<template>
  <TradeProcedureSidebar
    :title="title"
    :is-tabs="false"
    :is-main="false"
  />
  <TradeProcedure
    :url-set-rows="TRADE_OFFER_URL"
    :url-action-query="`${EXPAND_LOT}&search[Follow]`"
    is-chosen-icon
    :is-checkbox="false"
  />
</template>

<script>
import TradeProcedureSidebar from '@/components/views/TradeProcedure/VTradeProcedureSidebar'
import TradeProcedure from '@/components/views/TradeProcedure/VTradeProcedure'
import { TRADE_OFFER_URL, EXPAND_LOT } from '@/utils/consts'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      TRADE_OFFER_URL,
      EXPAND_LOT
    }
  },
  components: {
    TradeProcedureSidebar,
    TradeProcedure
  }
}
</script>
